<template>
  <div class="zbhd-container">
    <section-title :titleInfo="titleInfo"></section-title>

    <div class="zbhd-cont-list">
      <div class="zbhd-cont-item">
        <div class="cont-item-pic"></div>
        <div class="cont-item-text">
          学习《中国共产党纪律处分条例》第一篇 “总则”
        </div>
      </div>
      <div class="zbhd-cont-item">
        <div class="cont-item-pic"></div>
        <div class="cont-item-text">
          学习《中国共产党纪律处分条例》第一篇 “总则”
        </div>
      </div>
      <div class="zbhd-cont-item">
        <div class="cont-item-pic"></div>
        <div class="cont-item-text">
          学习《中国共产党纪律处分条例》第一篇 “总则”
        </div>
      </div>
      <div class="zbhd-cont-item">
        <div class="cont-item-pic"></div>
        <div class="cont-item-text">
          学习《中国共产党纪律处分条例》第一篇 “总则”
        </div>
      </div>
      <div class="zbhd-cont-item">
        <div class="cont-item-pic"></div>
        <div class="cont-item-text">
          学习《中国共产党纪律处分条例》第一篇 “总则”
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import sectionTitle from "../../components/section-title.vue";
export default {
  components: { sectionTitle },
  data() {
    return {
      titleInfo: {
        bigtext1: "支部活动",
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.zbhd-container {
  width: 100%;
  background-color: #f6f6f6;
  box-sizing: border-box;
  padding: 160px;
  .zbhd-cont-list {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    .zbhd-cont-item {
      flex-basis: 31%;
      margin-right: 20px;
      margin-bottom: 20px;
      height: 474px;
      background-color: #fff;
      box-shadow: 0px 12px 22px 1px rgba(#070921, 0.1);
      .cont-item-pic {
        width: 100%;
        height: 308px;
        background-image: url("../images/zbhd-item-bg.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-position: center;
      }
      .cont-item-text {
        width: 100%;
        height: 166px;
        box-sizing: border-box;
        padding: 30px;
        font-weight: bold;
        font-size: 22px;
        color: #1d1d1d;
        line-height: 48px;
      }
    }
  }
}
</style>


