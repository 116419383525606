<template>
  <div class="ryzz-container">
    <div class="ryzz-top-container">
      <section-title :titleInfo="firstPartTitleInfo"></section-title>
      <img class="zz-img" src="../images/rongyuzizhi-bg2.png" alt="" />
      <div class="jssl">
        <div class="jssl-title">技术实力</div>
        <div class="jssl-item-show">
          <div class="jssl-item-num">25+</div>
          <div class="jssl-item-label">专利证书</div>
        </div>
        <div class="jssl-item-show">
          <div class="jssl-item-num">28+</div>
          <div class="jssl-item-label">软件著作</div>
        </div>
      </div>
    </div>
    <div class="ryzz-bottom-container">
      <div class="ryzz-bottom-tips">
        <div class="ryzz-bottom-tips-wrapper">
          <div class="pinpairongyu">品牌荣誉</div>
          <div class="ry-list">
            <div class="ry-item">
              <div class="ry-item-num">10+</div>
              <div class="ry-item-label">省市级荣誉</div>
            </div>
            <div class="ry-item">
              <div class="ry-item-num">16+</div>
              <div class="ry-item-label">品牌荣誉</div>
            </div>
            <div class="ry-item">
              <div class="ry-item-num">10+</div>
              <div class="ry-item-label">人才荣誉</div>
            </div>
          </div>
        </div>
      </div>
      <div class="ryzz-bottom-cont">
        <div class="ryzz-bottom-item">
            <p>国家级专精特新“小巨人”企业</p>
            <p>省级专精特新企业</p>
            <p>3A认证企业</p>
            <p>ISO认证企业</p>
            <p>高新技术企业</p>
        </div>
        <div class="ryzz-bottom-item">
            <p>技术/研究中心认证企业</p>
            <p>省级企业技术中心（工信）</p>
            <p>省工程技术研究中心（科技）</p>
            <p>CMMI三级证书</p>
            <p>软件企业证书（双软）</p>
        </div>
        <div class="ryzz-bottom-item">
            <p>ISO9001质量管理体系认证</p>
            <p>ISO14001环境管理体系认证</p>
            <p>ISO45001职业健康安全管理体系认证</p>
            <p>ISO20000信息技术服务管理体系认证</p>
            <p>ISO27001信息安全管理体系认证</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SectionTitle from "../../components/section-title.vue";
export default {
  components: { SectionTitle },
  data() {
    return {
      firstPartTitleInfo: {
        bigtext1: "荣誉资质",
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.ryzz-container {
  width: 100%;
  .ryzz-top-container {
    width: 100%;
    height: 1080px;
    box-sizing: border-box;
    padding: 100px 160px;
    background-image: url("../images/rongyuzizhi-bg1.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
    position: relative;
    .zz-img {
      position: absolute;
      width: 1006px;
      height: 651px;
      bottom: 94px;
      right: 190px;
      z-index: 99;
    }
    .jssl {
      width: 300px;
      position: absolute;
      left: 224px;
      top: 300px;
      .jssl-title {
        font-weight: 400;
        font-size: 32px;
        color: #1d1d1d;
        margin-bottom: 74px;
      }
      .jssl-item-show {
        color: #1f63ea;
        margin-bottom: 60px;
        .jssl-item-num {
          font-weight: bold;
          font-size: 32px;
          margin-bottom: 16px;
        }
        .jssl-item-label {
          font-weight: 400;
          font-size: 18px;
        }
      }
    }
  }
  .ryzz-bottom-container {
    width: 100%;
    position: relative;
    box-sizing: border-box;
    padding: 88px 160px;
    .ryzz-bottom-tips {
      width: 100%;
      position: absolute;
      box-sizing: border-box;
      padding: 0 160px;
      top: -94px;
      left: 0;
      .ryzz-bottom-tips-wrapper {
        width: 100%;
        height: 158px;
        box-sizing: border-box;
        padding: 44px 64px;
        background-image: url("../images/rongyuzizhi-bg3.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        .pinpairongyu {
          font-size: 32px;
          color: #1d1d1d;
        }
        .ry-list {
          position: absolute;
          top: 44px;
          right: 67px;
          width: 700px;
          height: 102px;
          display: flex;
          justify-content: space-around;
          .ry-item {
            width: 222px;
            height: 102px;
            background-color: #1f63ea;
            box-sizing: border-box;
            padding: 14px 38px;
            color: #fff;
            .ry-item-num {
              font-weight: bold;
              font-size: 32px;
            }
            .ry-item-label {
              font-weight: 400;
              font-size: 18px;
            }
          }
        }
      }
    }
    .ryzz-bottom-cont{
        width: 100%;
        display: flex;
        box-sizing: border-box;
        padding: 20px 40px;
        justify-content: space-around;
        .ryzz-bottom-item{
            flex-basis: 32%;
            background-color: #F6F6F6;
            box-sizing: border-box;
            padding: 30px;
            p{
                font-weight: 400;
                font-size: 22px;
                color: #1D1D1D;
                margin-bottom: 20px;
                &::before{
                    content: "";
                    display: inline-block;
                    width: 8px;
                    height: 4px;
                    background: #1F63EA;
                    vertical-align: middle;
                    margin-right: 10px;
                }
            }


        }
    }
  }
}
</style>


