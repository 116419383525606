<template>
  <div class="qywh-container">
    <section-title :titleInfo="titleInfo"></section-title>

    <div class="qywh-cont-list">
      <div class="qywh-cont-item qywh-cont-item1">
        <div class="qywh-ci-cont">
            <div class="ci-cont-logo">
                <img class="ci-cont-logo-pic" src="../images/qywh-item-logo1.png" alt="">
            </div>
            <div class="ci-cont-title">
                智融使命
            </div>
            <div class="ci-cont-text">
                智控电力，融慧未来 —— 数字科技赋能卓越运营
            </div>
        </div>
      </div>
      <div class="qywh-cont-item qywh-cont-item2">
        <div class="qywh-ci-cont">
            <div class="ci-cont-logo">
                <img class="ci-cont-logo-pic" src="../images/qywh-item-logo2.png" alt="">
            </div>
            <div class="ci-cont-title">
                智融愿景
            </div>
            <div class="ci-cont-text">
                携手数智前沿，重塑能源未来
            </div>
        </div>
      </div>
      <div class="qywh-cont-item qywh-cont-item3">
        <div class="qywh-ci-cont">
            <div class="ci-cont-logo">
                <img class="ci-cont-logo-pic" src="../images/qywh-item-logo3.png" alt="">
            </div>
            <div class="ci-cont-title">
                智融价值观
            </div>
            <div class="ci-cont-text">
                诚信、创新、专注、极致、奋进、贡献
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import sectionTitle from "../../components/section-title.vue";
export default {
  components: { sectionTitle },
  data() {
    return {
      titleInfo: {
        bigtext1: "企业文化",
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.qywh-container {
  width: 100%;
  background-color: #f6f6f6;
  box-sizing: border-box;
  padding: 160px;
  .qywh-cont-list {
    width: 100%;
    display: flex;
    margin-top: 60px;
    justify-content: space-around;
    .qywh-cont-item {
      flex-basis: 32%;
      height: 740px;
      .qywh-ci-cont{
        width: 100%;
        height: 100%;
        position: relative;
        box-sizing: border-box;
        padding: 46px 58px;
        cursor: pointer;
        .ci-cont-logo{
            width: 100%;
            height: 68px;
            margin-bottom: 20px;
            .ci-cont-logo-pic{
                display: inline-block;
                width: 68px;
                height: 68px;
            }
        }
        .ci-cont-title{
            font-weight: 400;
            font-size: 32px;
            color: #FFFFFF;
            margin-bottom: 20px;
        }
        .ci-cont-text{
            font-weight: 400;
            font-size: 22px;
            line-height: 38px;
            color: #D4E6FF;
        }
        &:hover{
            background-image: url('../images/qywh-item-mb.png');
            background-repeat: no-repeat;
            background-size: 100% 100%;
            background-position: center;
        }
      }

    }
    .qywh-cont-item1 {
      background-image: url("../images/qywh-item-bg1.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      background-position: center;
    }
    .qywh-cont-item2 {
      background-image: url("../images/qywh-item-bg2.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      background-position: center;
    }
    .qywh-cont-item3 {
      background-image: url("../images/qywh-item-bg3.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      background-position: center;
    }
  }
}
</style>


