<template>
    <div class="hzhb-container">
        <div class="hzhb-text">
            智融科技在智慧运营方面以合作共赢、共创未来为宗旨，邀请全行业客户成为我们的合作伙伴，我们也将从管理、运营、服务等方面帮助充电运营商、桩企、车企、车运营商、政府监管部门等生态链伙伴全方位解决充电运营难题，带动新能源车电行业开启的全新充电运营模式，并保障每一位伙伴的权益，我们将是您最佳的合作伙伴。
        </div>
        <section-title :titleInfo="titleInfo"></section-title>
        
        <div class="hzhb-list">
            <div class="hzhb-item hzhb-item1" :class="{'hzhb-item-large': flag1}">
                <div class="hzhb-item-title">
                    <div class="hzhb-title-num">
                        <img class="hzhb-title-num-pic" src="../images/hzhb-num-one.png" alt="">
                    </div>
                    <div class="hzhb-title">
                        共同经营
                    </div>
                    <div class="title-arrow" :class="{'title-arrow-bottom': flag1}" @click="handleTranFlag1">
                    </div>
                </div>
                <div class="hzhb-item-cont">
                    加盟商可选择与我方按股份分成共同经营，并可接入我方平台，充分利用多个合伙人的资源和能力，提高企业的竞争力和创造力，同时减轻个人承担风险的压力。
                </div>

                <div class="hzhb-ys-container" v-if="flag1">
                    <div class="hzhb-ys-title">
                        优势
                    </div>
                    <div class="hzhb-ys-cont">
                        <div class="hzhb-ys-cont-item">
                            <span class="cont-item-label">
                                资金充足
                            </span>：多个合伙人共同投入资金，可以提供更多的资金用于业务运营和发展。
                        </div>
                        <div class="hzhb-ys-cont-item">
                            <span class="cont-item-label">
                                技能和经验的互补
                            </span>：合伙人之间可以互相补充技能和经验，提高业务的专业性和竞争力。
                        </div>
                        <div class="hzhb-ys-cont-item">
                            <span class="cont-item-label">
                                资源共享
                            </span>：合伙人之间可以共享资源，如人脉关系、客户资源等，扩大业务发展。
                        </div>
                    </div>
                </div>

                <div class="hzhb-btn-container">
                    <div class="hzhb-btn">
                        成为合作伙伴
                    </div>
                </div>
            </div>
            <div class="hzhb-item hzhb-item2" :class="{'hzhb-item-large': flag2}">
                <div class="hzhb-item-title">
                    <div class="hzhb-title-num">
                        <img class="hzhb-title-num-pic" src="../images/hzhb-num-two.png" alt="">
                    </div>
                    <div class="hzhb-title">
                        三方合作
                    </div>
                    <div class="title-arrow" :class="{'title-arrow-bottom': flag2}" @click="handleTranFlag2">
                    </div>
                </div>
                <div class="hzhb-item-cont">
                    与引流平台和充电桩供应商建立合作关系，将充电桩的实时数据和位置信息提供给引流平台，确保引流平台提供的充电信息准确性和实时性。通过引流平台提供的充电服务信息，吸引用户使用其管辖区域内的充电桩，从而增加设施的利用率和收入。各方通过技术和服务的协同合作，共同推动充电基础设施的发展和利用率提升。
                </div>

                <div class="hzhb-ys-container" v-if="flag2">
                    <div class="hzhb-ys-title">
                        优势
                    </div>
                    <div class="hzhb-ys-cont">
                        <div class="hzhb-ys-cont-item">
                            <span class="cont-item-label">
                                扩大市场
                            </span>：基于第三方平台加入我们，可借助平台的品牌影响力和用户基础，快速进入新市场，扩大业务范围。
                        </div>
                        <div class="hzhb-ys-cont-item">
                            <span class="cont-item-label">
                                减少工作量
                            </span>：可以减少自行开展市场调研、营销策划等工作的时间和精力，快速进入市场并专注于核心业务。
                        </div>
                        <div class="hzhb-ys-cont-item">
                            <span class="cont-item-label">
                                节约成本
                            </span>：可以共享资源和成本，减少重复性投入和浪费。通过合作，共同利用设备、人力和技术等资源，降低合作过程中的成本，提高效率和利润。
                        </div>
                    </div>
                </div>

                <div class="hzhb-btn-container">
                    <div class="hzhb-btn">
                        成为合作伙伴
                    </div>
                </div>
            </div>
            <div class="hzhb-item hzhb-item3" :class="{'hzhb-item-large': flag3}">
                <div class="hzhb-item-title">
                    <div class="hzhb-title-num">
                        <img class="hzhb-title-num-pic" src="../images/hzhb-num-three.png" alt="">
                    </div>
                    <div class="hzhb-title">
                        特许经营
                    </div>
                    <div class="title-arrow" :class="{'title-arrow-bottom': flag3}" @click="handleTranFlag3">
                    </div>
                </div>
                <div class="hzhb-item-cont">
                    加盟商可以在授权范围内经营并销售我方的产品或服务，并通常享受特许权人提供的支持和指导。
                </div>

                <div class="hzhb-ys-container" v-if="flag3">
                    <div class="hzhb-ys-title">
                        优势
                    </div>
                    <div class="hzhb-ys-cont">
                        <div class="hzhb-ys-cont-item">
                            <span class="cont-item-label">
                                高品牌知名度
                            </span>：加盟商可以使用我司已经建立的品牌，无需从零开始建立品牌认知度。
                        </div>
                        <div class="hzhb-ys-cont-item">
                            <span class="cont-item-label">
                                经营支持和指导
                            </span>：我司通常提供培训、运营指导、营销支持等方面的支持，帮助加盟商更。
                        </div>
                        <div class="hzhb-ys-cont-item">
                            <span class="cont-item-label">
                                共享市场经验和成功模式
                            </span>：加盟商可以分享我方在市场上积累的经验和成功模式，从而降低经营风险。更快速的启动和扩大业务。
                        </div>
                    </div>
                </div>

                <div class="hzhb-btn-container">
                    <div class="hzhb-btn">
                        成为合作伙伴
                    </div>
                </div>
            </div>
        </div>


    </div>
</template>

<script>
import sectionTitle from '../../components/section-title.vue'
    export default {
        components: { sectionTitle },
        data(){
            return {
                titleInfo:{
                    bigtext1: "合作模式",
                },
                flag1: false,
                flag2: false,
                flag3: false
            }
        },

        methods:{
            handleTranFlag1(){
                this.flag1 = !this.flag1
            },
            handleTranFlag2(){
                this.flag2 = !this.flag2
            },
            handleTranFlag3(){
                this.flag3 = !this.flag3
            }
        }
        


    }
</script>

<style lang="scss" scoped>
.hzhb-container{
    width: 100%;
    box-sizing: border-box;
    padding: 100px 160px;
    background-color: #F6F6F6;
    .hzhb-text{
        width: 100%;
        font-weight: 400;
        font-size: 18px;
        color: #1D1D1D;
        line-height: 36px;
        margin-bottom: 100px;
    }
    .hzhb-list{
        width: 100%;
        display: flex;
        justify-content: space-around;
        .hzhb-item{
            flex-basis: 32%;
            height: 500px;
            background: #FFFFFF;
            box-shadow: 0px 25px 89px 1px rgba(31,99,234,0.12);
            border-radius: 12px 12px 12px 12px;
            box-sizing: border-box;
            padding: 0 41px;
            position: relative;
            .hzhb-item-title{
                width: 100%;
                height: 90px;
                line-height: 100px;
                border-bottom: 1px solid #D4E6FF;
                position: relative;
                margin-bottom: 20px;
                .hzhb-title-num{
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 65px;
                    height: 68px;
                    z-index: 99;
                    background: linear-gradient( 180deg, rgba(31,99,234,0.95) 0%, rgba(31,99,234,0) 100%);
                    .hzhb-title-num-pic{
                        position: absolute;
                        width: 22px 36px;
                        top: 10px;
                        left: 22px;
                        z-index: 999;
                    }
                }
                .hzhb-title{
                    width: 100%;
                    height: 100%;
                    font-weight: bold;
                    font-size: 32px;
                    color: #1D1D1D;
                }
                .title-arrow{
                    position: absolute;
                    width: 38px;
                    height: 38px;
                    top: 30px;
                    right: 0px;
                    cursor: pointer;
                    background-image: url('../images/hzhb-arrow-right.png');
                    background-size: 100% 100%;
                    background-position: center;
                    background-repeat: no-repeat;
                }
                .title-arrow-bottom{
                    background-image: url('../images/hzhb-arrow-bottom.png');
                }
            }
            .hzhb-item-cont{
                width: 100%;
                font-weight: 400;
                font-size: 18px;
                color: #1D1D1D;
                line-height: 36px;
            }
            .hzhb-ys-container{
                width: 100%;
                box-sizing: border-box;
                padding: 30px 0 50px;
                .hzhb-ys-title{
                    width: 100%;
                    height: 24px;
                    background-image: url('../images/hzhb-ys-bg.png');
                    background-size: 100% 100%;
                    background-repeat: no-repeat;
                    background-position: center;
                    color: #1F63EA;
                    font-size: 18px;
                    margin-bottom: 20px;
                }
                .hzhb-ys-cont{
                    width: 100%;
                    .hzhb-ys-cont-item{
                        margin-bottom: 20px;
                        color: #1D1D1D;
                        font-size: 18px;
                        font-weight: 400;
                        .cont-item-label{
                            font-weight: bold;
                        }
                    }

                }

            }

            .hzhb-btn-container{
                position: absolute;
                bottom: 30px;
                left: 0;
                width: 100%;
                text-align: center;
                height: 58px;
                /* margin-bottom: 43px;
                margin-top: 40px; */
                .hzhb-btn{
                    width: 186px;
                    height: 58px;
                    line-height: 58px;
                    font-weight: 400;
                    font-size: 18px;
                    color: #FFFFFF;
                    cursor: pointer;
                    display: inline-block;
                    background-image: url('../images/hzhb-btn-bg.png');
                    background-repeat: no-repeat;
                    background-size: 100% 100%;
                    background-position: center;
                }
            }
        }
        .hzhb-item-large{
            height: 900px;
        }
    }

}


</style>


