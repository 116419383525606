<template>
  <div>
    <Nav></Nav>
    <banner-part :bannerInfo="nowBannerInfo"></banner-part>
    <div class="container">
      <tabs-section
        :defaultIndex="defaultIndex"
        :tabsList="tabsList"
      ></tabs-section>

      <gsjj v-if="nowSectionIndex == '1'"></gsjj>
      <ryzz v-if="nowSectionIndex == '2'"></ryzz>
      <hzhb v-if="nowSectionIndex == '3'"></hzhb>
      <qywh v-if="nowSectionIndex == '4'"></qywh>
      <zbhd v-if="nowSectionIndex == '5'"></zbhd>
    </div>
    <Footer class="top" />
  </div>
</template>

<script>
import BannerPart from "../components/BannerPart.vue";
import TabsSection from "../components/tabsSection.vue";
import Gsjj from "./cpns/gsjj.vue";
import Hzhb from './cpns/hzhb.vue';
import Qywh from './cpns/qywh.vue';
import Ryzz from './cpns/ryzz.vue';
import Zbhd from './cpns/zbhd.vue';
export default {
  components: { BannerPart, TabsSection, Gsjj, Ryzz, Hzhb, Qywh, Zbhd },
  data() {
    return {
      bannerInfo: {
        text1: "关于智融",
        text2: "关于智融底下小字",
      },
      bannerInfoList:{
        '1':{
          text1: "公司简介",
          text2: "做国内一流的智慧能源解决方案及服务提供商",
          swiperImg: require("./images/gsjj-banner-pic.png")
        },
        '2':{
          text1: "荣誉资质",
          text2: "做国内一流的智慧能源解决方案及服务提供商",
          swiperImg: require("./images/ryzz-banner-pic.png")
        },
        '3':{
          text1: "合作伙伴",
          text2: "合作共赢，共创未来",
          swiperImg: require("./images/hzhb-banner-pic.png")
        },
        '4':{
          text1: "企业文化",
          text2: "做国内一流的智慧能源解决方案及服务提供商",
          swiperImg: require("./images/qywh-banner-pic.png")
        },
        '5':{
          text1: "党建引领",
          text2: "全面贯彻习近平新时代中国特色社会主义思想",
          swiperImg: require("./images/djyl-banner-pic.png"),
          blackcolor: true
        }
      },

      tabsList: [
        {
          value: "1",
          label: "公司简介",
          path: "/gyzr/1",
        },
        {
          value: "2",
          label: "荣誉资质",
          path: "/gyzr/2",
        },
        {
          value: "3",
          label: "合作伙伴",
          path: "/gyzr/3",
        },
        {
          value: "4",
          label: "企业文化",
          path: "/gyzr/4",
        },
        {
          value: "5",
          label: "党建引领",
          path: "/gyzr/5",
        },
      ],
      defaultIndex: "1",
    };
  },
  computed:{
    nowSectionIndex(){
      return this.$route.params.index || '1'
    },
    nowBannerInfo(){
      return this.bannerInfoList[this.nowSectionIndex]
    }
  },

  mounted() {
    this.defaultIndex = this.$route.params.index || "1";
    console.log("this.defaultIndex>>>", this.defaultIndex);
  },
};
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  min-height: 600px;
}
</style>