<template>
  <div class="gsjj-container">
    <div class="gsjs-container">
      <section-title :titleInfo="firstPartTitleInfo"></section-title>

      <div class="gsjs-content">
        <span class="gs-mc">江苏智融能源科技有限公司</span
        >自2016年成立以来，已荣耀地跨越了多个质量与管理里程碑，不仅成功斩获ISO质量、环境及职业健康安全管理体系的权威认证，还顺利通过了GB/T29490知识产权管理体系的严格审核，彰显了我们在行业标准遵循与创新能力上的卓越追求。此外，我们相继荣获江苏省专精特新中小企业认定、南京市工程技术研究中心、南京市市级企业技术中心认证，ITSS三级与CMMI三级等国际认可资质。并且，公司通过共建形式成立了江苏省省级企业技术中心，并与包括东南大学在内的多所高校和科研院所建立长期产学研合作机制。<br />
        公司作为深耕新能源与智慧电力领域的数字化解决方案的高新技术企业先锋，在电力领域以人工智能技术实现智能辅助与人力替代，在能源领域以数据融合技术实现能源高效利用。我们的远程智能巡视系统、配电智辅及物联系统广泛应用于包括电力在内的多个生产运营场景，实现了人力替代，提高了生产效率；同时，智慧能源调控平台和广域车网互动公共服务模块已成功在省域范围成功上线运营，构建了包括储能、光伏、充电多要素的能源聚合、调控以及运营能力。通过打通多方数据链路，实现数据融合，打造了包括车、桩、路、网在内的交能融合新体系，为绿色出行，高效用能，智慧交通提供全方位、定制化的运营策略。<br />
        公司依托工程技术中心和企业技术中心，携手高校共建了物联网实验室、图像识别实验室、车网互动实验室等一批前沿科研阵地，持续产出了一批高质量研究成果和实际应用。公司先后参与了多个物联网示范区项目建设和国重课题研究，特别是再电网多层级调度和资源聚合方面通过省部级鉴定达到国内领先、国际先进水平，已累计获得各类知识产权授权达65项，其中发明专利授权25项。<br />
        公司正紧抓新型电力系统数字化转型的历史机遇，同时紧密结合新能源发展的蓬勃趋势构建起一套成熟且全面的数智电力与能源服务生态体系，我们矢志成为数字化新能源管理领域的全方位解决方案领航者。
      </div>
    </div>

    <div class="lxwm-container">
      <div class="lxwm-title">
        <section-title :titleInfo="secondPartTitleInfo"></section-title>
      </div>
      <div class="lxwm-cont1">
        <div class="lxwm-gs-mc">
          <div class="mc-zh">江苏智融能源科技有限公司</div>
          <div class="mc-en">JIANGSU ZHIRONG ENERGY TECHNOLOGY CO.,LTD</div>
        </div>

        <div class="lwxm-other-info">
          <div class="lxwm-item-info">
            <div class="lxwm-item-sm-title">电话:</div>
            <div class="lxwm-item-sm-value">025-83585700</div>
          </div>
          <div class="lxwm-item-info">
            <div class="lxwm-item-sm-title">邮箱:</div>
            <div class="lxwm-item-sm-value">zhirongtechnology@163.com</div>
          </div>
        </div>

        <div class="lwxm-other-info">
          <div class="lxwm-item-info">
            <div class="lxwm-item-sm-title">地址:</div>
            <div class="lxwm-item-sm-value">
              南京市雨花台区软件大道118号新华汇B4栋5层
            </div>
          </div>
        </div>
      </div>
      <div class="lxwm-map">

      </div>
    </div>
  </div>
</template>

<script>
import SectionTitle from "../../components/section-title.vue";
export default {
  components: {
    SectionTitle,
  },
  data() {
    return {
      firstPartTitleInfo: {
        bigtext1: "公司介绍",
      },
      secondPartTitleInfo: {
        bigtext1: "联系我们",
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.gsjj-container {
  width: 100%;
  background-color: #f6f6f6;
  .gsjs-container {
    width: 100%;
    height: 1080px;
    box-sizing: border-box;
    padding: 100px 160px;
    position: relative;
    background-image: url("../images/gongsijieshao-bg.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
    .gsjs-content {
      width: 800px;
      font-weight: 400;
      font-size: 18px;
      color: #1d1d1d;
      line-height: 32px;
      .gs-mc {
        font-weight: bold;
      }
    }
  }
  /* .part-common {
    width: 100%;
    height: 1080px;
    box-sizing: border-box;
    padding: 100px 160px;
    position: relative;
  } */
  .lxwm-container {
    width: 100%;
    /* height: 1080px; */
    .lxwm-title {
      width: 100%;
      box-sizing: border-box;
      padding: 100px 160px 40px;
    }
    .lxwm-cont1 {
      width: 100%;
      height: 369px;
      box-sizing: border-box;
      padding: 64px 160px 37px;
      background-image: url("../images/lianxiwomen-bg.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      background-position: center;
      color: #fff;
      .lxwm-gs-mc {
        margin-bottom: 60px;
        .mc-zh {
          font-weight: bold;
          font-size: 32px;
          margin-bottom: 16px;
        }
        .mc-en {
          font-weight: 400;
          font-size: 16px;
          color: #d4e6ff;
        }
      }
      .lwxm-other-info {
        width: 100%;
        display: flex;
        margin-bottom: 20px;
        .lxwm-item-info {
          width: 500px;
          .lxwm-item-sm-title {
            font-weight: 400;
            font-size: 18px;
            margin-bottom: 10px;
            color: #d4e6ff;
          }
          .lxwm-item-sm-value {
            font-weight: 400;
            font-size: 22px;
            color: #ffffff;
          }
        }
      }
    }
    .lxwm-map{
        width: 100%;
        height: 695px;
        background-image: url('../images/lianxiwomen-map.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-position: center;
    }
  }
}
</style>

